/* eslint-disable react/no-unknown-property */
import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";

function formatTime(number) {
  return number < 10 ? "0" + number : number;
}
// TODO get rid of duplicate code when cleaning
const renderTimestamp = (date) => {
  const d = new Date(Date.parse(date));
  let hours = d.getHours();
  if (hours > 12) {
    hours %= 12;
    hours = hours ? hours : 12;
  }
  return `${d.getFullYear()}-${formatTime(d.getMonth() + 1)}-${formatTime(
    d.getDate()
  )} ${formatTime(hours)}:${formatTime(d.getMinutes())}${d.getHours() >= 12 ? "PM" : "AM"}`;
};

const reasonsForVisit = [    
  { key: "business_calls", label: "Business Calls" },
  { key: "methadone", label: "Methadone" },
  { key: "suboxone", label: "Suboxone" },
  { key: "safer_supply", label: "Safer Supply" },
  { key: "peer_counseling", label: "Peer Counselling" },
  { key: "health_card", label: "Health Card" },
  { key: "ontario_photo_id", label: "Ontario Photo ID" },
  { key: "birth_certificate", label: "Birth Certificate" },
  { key: "voluntaring_in_trailer_cts", label: "Voluntaring in Trailer/CTS" },
  { key: "emergency_appointment", label: "Emergency accompanied appointment" },
  { key: "reconnect_with_family_friends", label: "Re-connect with Family and Friends" },
  { key: "cmha_worker", label: "CMHA worker" },
  { key: "taxes", label: "Taxes" },
  { key: "housing_application", label: "Housing application" },
  { key: "go_to_treatment_service_access_recovery", label: "Go to Treatment/Service Access Recovery" },
  { key: "mental_health_referral", label: "Mental health referral" },
  { key: "spiritual_care_cultural_programming_traditional_medicine", label: "Seeking spiritual care/cultural programming/traditional medicine" },
  { key: "ontario_works_or_odsp", label: "Ontario Works or ODSP" },
  { key: "access_alt_shelter_services", label: "Access alternative shelter services (Cornerstone, Minwaashin etc...)" },
  { key: "facilitate_interactions_with_cas", label: "Facilitate interactions with CAS" },
  { key: "court_support", label: "Court support" }
];

const referrals = [
  { key: "withdrawl_management", label: "Withdrawal Management" },
  { key: "residential", label: "Residential" },
  { key: "addictions_services", label: "Addictions Services (SAR)" },
  { key: "safer_supply_referrals", label: "Safer Supply" },
  { key: "dentist", label: "Dentist" },
  { key: "cm_primary_care", label: "Primary Care" },
  { key: "cmha_cm_visit", label: "CMHA" },
  { key: "oat", label: "OAT" },
  { key: "options_bytown", label: "Option Bytown" },
  { key: "sal_van", label: "Sal Van" },
  { key: "telus_van", label: "Telus Van" }
];

const associations = [
  { key: "ted", label: "TED" },
  { key: "trailer", label: "Trailer" },
  { key: "safer_supply_client_associations", label: "Safer Supply" },
  { key: "community", label: "Community" }
];

function ClientVisitHistory(props) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const client_visit_history_data = useMemo(() => {
    if (!Array.isArray(props.client_visit_history_data)) {
      return [];
    }
    return props.client_visit_history_data;
  }, [props]);

  const get_tags = useMemo(() => {
    if (!Array.isArray(props.get_tags)) {
      return [];
    }
    return props.get_tags;
  }, [props]);

  useEffect(() => {
    if (isModalOpen) {
      const body = document.body;
      body.style.overflowY = "none";

      const html = document.documentElement;
      html.style.overflowY = "none";
    } else {
      const body = document.body;
      body.style.overflowY = "auto";

      const html = document.documentElement;
      html.style.overflowY = "auto";
    }
  }, [isModalOpen]);

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const displayBooleansForVisit = (client_visit, boolean_definitions) => {
    return boolean_definitions.map(boolean_definition => {
      if (client_visit[boolean_definition.key]) {
        return <>- {boolean_definition.label} <br /> <br /> </>;
      } 
      if (client_visit[boolean_definition.tag_symbol]) {
        if (boolean_definition.tag_name == "Other"){
          return <>- {boolean_definition.tag_name} ({boolean_definition.category_name}) - {client_visit[boolean_definition.tag_symbol]} <br /> <br /></>
        } else {
          return <>- {boolean_definition.tag_name} <br /> <br /> </>
        }
      }
      return (
        <></>
      );
    });
  };

  // TODO replace table components with ReUsableTable components when merged
  return (
    <>
      <div className="cm-button">
        <div onClick={() => setIsModalOpen(!isModalOpen)} className="button is-primary" data-explorator_test_id={"client-visit-history-modal-open-modal-button"}>
          Client Visit History
        </div>
      </div>
      {isModalOpen && (
        <>
          <div className="client-visit-modal-outer-container">
            <div
              className="client-visit-modal-container"
            >
              <div className="client-visit-modal-content">
                <header className="modal-card-head">
                  <button
                    className="delete"
                    aria-label="close"
                    type="button"
                    onClick={closeModal}
                  ></button>
                </header>
                <section className="modal-card-body">
                  <table className="table is-fullwidth">
                    <thead>
                      <tr>
                        <th>Date Created</th>
                        <th>Visit Summary</th>
                        <th>Client Chart</th>
                        <th>Health Issue</th>
                        <th>Reason for Visit <b>(New)</b></th>
                        <th>Reason for Visit <b>(Old)</b></th>
                        <th>Referrals</th>
                        <th>Client Associations</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      { 
                        client_visit_history_data.length === 0 &&
                        <tr>
                          <td colSpan={8}>
                            <p className="client-visit-modal-no-content-text">No Entries</p>
                          </td>
                        </tr>
                      }
                      {client_visit_history_data.map((client_visit) => (
                        <tr key={client_visit.id}>
                          <td data-explorator_test_id={"client-visit-history-modal-table-created-at-" + client_visit.id} style={{ whiteSpace: "nowrap" }}>
                            {renderTimestamp(client_visit.created_at)}
                          </td>
                          <td data-explorator_test_id={"client-visit-history-modal-table-visit-summary-" + client_visit.id}>{client_visit.visit_summary}</td>
                          <td data-explorator_test_id={"client-visit-history-modal-table-client-chart-" + client_visit.id}>{client_visit.client_chart}</td>
                          <td data-explorator_test_id={"client-visit-history-modal-table-health-issue-" + client_visit.id}>{client_visit.health_issue}</td>
                          <td data-explorator_test_id={"client-visit-history-modal-table-reasons-for-visit-new-" + client_visit.id}>{displayBooleansForVisit(client_visit, get_tags)}</td>
                          <td className="has-text-grey" data-explorator_test_id={"client-visit-history-modal-table-reasons-for-visit-" + client_visit.id}>{displayBooleansForVisit(client_visit, reasonsForVisit)}</td>
                          <td className="has-text-grey" data-explorator_test_id={"client-visit-history-modal-table-referrals-" + client_visit.id}>{displayBooleansForVisit(client_visit, referrals)}</td>
                          <td className="has-text-grey" data-explorator_test_id={"client-visit-history-modal-table-associations-" + client_visit.id}>{displayBooleansForVisit(client_visit, associations)}</td>
                          <td>
                            <a
                              data-explorator_test_id={
                                "client_visit_history_modal_link_" + client_visit.id
                              }
                              href={`/case_management_clients/${client_visit.case_management_client_id}/case_manager_visits/${client_visit.id}`}
                            >
                              View Case
                            </a>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </section>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

ClientVisitHistory.propTypes = {
  client_visit_history_data: PropTypes.array,
  get_tags: PropTypes.array
};

export default ClientVisitHistory;