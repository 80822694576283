import React, { useContext } from "react";
import ColSpanPadding from "./ColspanPadding";
import { DynamicTableContext } from "../dynamic_table";
import ColumnHeading from "./ColumnHeading";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

const canadaTimezone = "America/Toronto";

function DynamicTableComponent() {
  const { data, filteredData, columns } = useContext(DynamicTableContext);

  return (
    <table className="table is-striped is-fullwidth">
      <thead>
        <tr>
          {columns.map((column, index) => (
            <th key={index}>
              <ColumnHeading heading={column} index={index} />
            </th>
          ))}
          <ColSpanPadding data={data} columns={columns} />
        </tr>
      </thead>
      <tbody>
        {filteredData.map((row, index) => (
          <tr key={index}>
            {Object.keys(row).map((rowKey, r_index) =>
              rowKey === "createdDate" ? (
                <td key={`${rowKey} ${r_index}`}>
                  {row[rowKey] &&
                    dayjs.utc(row[rowKey]).tz(canadaTimezone).format("YYYY-MM-DD HH:mm:ss ZZ")}
                </td>
              ) : (
                <td key={`${rowKey} ${r_index}`}>
                  {_isHtmlTag(row[rowKey]) ? (
                    <div dangerouslySetInnerHTML={{ __html: row[rowKey] }} />
                  ) : (
                    row[rowKey]
                  )}
                </td>
              )
            )}
          </tr>
        ))}
      </tbody>
    </table>
  );
}

function _isHtmlTag(str) {
  const regex = /^<([a-z]+)([^<]+)*(?:>(.*)<\/\1>|\s+\/>)$/;
  return regex.test(str);
}

export default DynamicTableComponent;
