import axios from "axios";
import React, { useEffect, useState } from "react";

function IncidentClientVisitModal() {
  const [linkedClientVisitId, setLinkedClientVisitId] = useState(undefined);
  const [clientVisits, setClientVisits] = useState([]);
  const [page, setPage] = useState(1);
  const [prevPage, setPrevPage] = useState(-1);
  const [isOpened, setIsOpened] = useState(false);

  const [incidentIsHappenedDuringAVisit, setIncidentIsHappenedDuringAVisit] = useState(false);

  useEffect(() => {
    retrieveClientVisits(true);
  }, [page, incidentIsHappenedDuringAVisit]);
  useEffect(() => {
    if ($("#incident_incident_is_happened_during_a_visit_true").is(":checked")) {
      setIncidentIsHappenedDuringAVisit(true);
    }
    $("#incident_incident_is_happened_during_a_visit_true").on("change", function () {
      setIncidentIsHappenedDuringAVisit(true);
      $("#incident-client-visit-modal-container").addClass("is-active is-clipped");
      setPage(1);
    });
    $("#incident_incident_is_happened_during_a_visit_false").on("change", function () {
      setIncidentIsHappenedDuringAVisit(false);
      setPage(1);
    });
    return () => {
      $("#incident_incident_is_happened_during_a_visit_true").off("change");
      $("#incident_incident_is_happened_during_a_visit_false").off("change");
    };
  }, []);

  useEffect(() => {
    // Observer changes to the class name of the #incident-client-visit-modal-container element.
    const element = document.getElementById("incident-client-visit-modal-container");
    const observer = new MutationObserver(() => {
      if (element.className.includes("is-active")) {
        setIsOpened(true);
        document.documentElement.style.overflow = "hidden";
      } else {
        setIsOpened(false);
        setPage(1);
        document.documentElement.style.overflow = "auto";
      }
    });
    observer.observe(element, { attributes: true });

    // Clean up observer on unmount
    return () => {
      observer.disconnect();
    };
  }, []);
  useEffect(() => {
    const el = document.getElementById("incident_client_visit_id");
    if (el.value !== "") {
      setLinkedClientVisitId(parseInt(el.value));
      switchLinkStatus(true);
    }

    const elObserver = new MutationObserver(() => {
      if (el.value !== "") {
        setLinkedClientVisitId(parseInt(el.value));
        switchLinkStatus(true);
      } else {
        setLinkedClientVisitId(undefined);
        switchLinkStatus(false);
      }
    });

    return () => {
      elObserver.disconnect();
    };
  }, []);

  function retrieveClientVisits(forceUpdate = false) {
    if (!forceUpdate && (!isOpened || prevPage === page)) {
      return;
    }
    const status = incidentIsHappenedDuringAVisit ? "in_progress" : "all";

    axios.get(`/client_visits.json?page=${page}&status=${status}`).then(({ data }) => {
      setClientVisits(data);
      setPrevPage(page);
    });
  }

  function closeModal() {
    document.getElementById("incident-client-visit-modal-container").className = "modal";
  }

  function linkClientVisit(clientVisitId) {
    document.getElementById("incident_client_visit_id").value = clientVisitId;
    switchLinkStatus(true);
    setLinkedClientVisitId(clientVisitId);
  }

  function unlinkClientVisit() {
    document.getElementById("incident_client_visit_id").value = "";
    switchLinkStatus(false);
    setLinkedClientVisitId(undefined);
  }

  function switchLinkStatus(value) {
    if (value) {
      document.getElementById("link-client-visit-indicator").style.display = "flex";
    } else {
      document.getElementById("link-client-visit-indicator").style.display = "none";
    }
  }

  function renderAction(clientVisitId, clientVisitIncidentId) {
    if (linkedClientVisitId === clientVisitId) {
      return <a onClick={unlinkClientVisit}>Unlink</a>;
    } else if (clientVisitIncidentId === undefined || clientVisitIncidentId == null) {
      return <a onClick={() => linkClientVisit(clientVisitId)}>Link to</a>;
    } else {
      return <p className="has-text-grey">Already linked</p>;
    }
  }

  function goNextPage() {
    if (clientVisits.length === 0) {
      return;
    }
    setPage(page + 1);
  }

  function goPrevPage() {
    if (page <= 1) {
      return;
    }
    setPage(page - 1);
  }

  return (
    <>
      <div className="modal-background" onClick={closeModal}></div>
      <div className="modal-card" style={{ width: "65vw", maxWidth: "1024px" }}>
        <header className="modal-card-head">
          <button className="delete" aria-label="close" type="button" onClick={closeModal}></button>
        </header>
        <section className="modal-card-body">
          <table className="table is-fullwidth">
            <thead>
              <tr>
                <th>Client Code</th>
                <th>DOB</th>
                <th>Visit At</th>
                <th>Status</th>
                <th colSpan="1"></th>
              </tr>
            </thead>
            <tbody>
              {clientVisits.length === 0 && !incidentIsHappenedDuringAVisit && (
                <tr>
                  <td colSpan="5" className="has-text-centered">
                    No visit found.
                  </td>
                </tr>
              )}
              {clientVisits.length === 0 && incidentIsHappenedDuringAVisit && (
                <>
                  <tr>
                    <td colSpan="5" className="has-text-centered">
                      No in-progress visit found.
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="5" className="has-text-centered">
                      <a
                        onClick={() => {
                          $("#incident_incident_is_happened_during_a_visit_false").trigger("click");
                        }}
                      >
                        See all visits
                      </a>
                    </td>
                  </tr>
                </>
              )}
              {clientVisits.length > 0 &&
                clientVisits.map((visit, i) => (
                  <tr key={i}>
                    <td>{visit.client.user_code}</td>
                    <td>{visit.client.dob}</td>
                    <td>{renderVisitAt(visit.visit_at)}</td>
                    <td>
                      <span className={`tag ${visit.status_class}`}>{visit.friendly_status}</span>
                    </td>
                    <td>{renderAction(visit.id, visit.incident_id)}</td>
                  </tr>
                ))}
            </tbody>
          </table>
          <div className="field is-grouped">
            <div className="control">
              <button
                type="button"
                className="button is-light"
                onClick={goPrevPage}
                disabled={page <= 1}
              >
                Prev
              </button>
            </div>
            <div className="control" style={{ display: "flex", alignItems: "center" }}>
              <label className="label">{`Page ${page}`}</label>
            </div>
            <div className="control">
              <button
                type="button"
                className="button is-light"
                onClick={goNextPage}
                disabled={clientVisits.length < 10}
              >
                Next
              </button>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
export default IncidentClientVisitModal;

function formatTime(number) {
  return number < 10 ? "0" + number : number;
}

function renderVisitAt(date) {
  const d = new Date(Date.parse(date));
  let hours = d.getHours();
  if (hours > 12) {
    hours %= 12;
    hours = hours ? hours : 12;
  }
  return `${d.getFullYear()}-${formatTime(d.getMonth() + 1)}-${formatTime(
    d.getDate()
  )} ${formatTime(hours)}:${formatTime(d.getMinutes())}${d.getHours() >= 12 ? "PM" : "AM"}`;
}
