import React from "react";
import PropTypes from "prop-types";
function ColSpanPadding({ data, columns }) {
  const colspanPaddingLength = (() => {
    if (data.length > 0) {
      return Object.keys(data[0]).length - columns.length;
    } else {
      return 0;
    }
  })();
  if (colspanPaddingLength > 0) return <th colSpan={colspanPaddingLength}></th>;
  return <></>;
}
ColSpanPadding.propTypes = {
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired
};

export default ColSpanPadding;
