import { useEffect } from "react";
import "bulma-calendar/dist/css/bulma-calendar.min.css";
import bulmaCalendar from "bulma-calendar/dist/js/bulma-calendar.min.js"; // TODO potential future issue is importing this multiple times

export const useBulmaCalendar = ({ id, customOptions, onUpdate }) => {
  useEffect(() => {
    // make start date 30 years ago
    const currentDate = new Date(); // Get the current date
    const year = currentDate.getFullYear(); // Get the current year
    const month = currentDate.getMonth(); // Get the current month (0-11)
    const day = currentDate.getDate(); // Get the current day of the month

    const thirtyYearsAgoDate = new Date(year - 30, month, day); // Subtract 30 years from the current year

    // Initialize all input of date type.
    const calendars = bulmaCalendar.attach(`[id="${id}"]`, {
      startDate: thirtyYearsAgoDate,
      ...customOptions
    });

    // Loop on each calendar initialized
    calendars.forEach((calendar) => {
      // Add listener to select event
      calendar.on("select", (date) => {
        onUpdate(date);
      });
      calendar.on("clear", () => {
        onUpdate(undefined);
      });
    });
  }, []);
  return null;
};
