import React, { useContext, useEffect, useState } from "react";
import { DynamicTableContext } from "../dynamic_table";

function TextFilterComponent() {
  const [userInput, setUserInput] = useState("");
  const { data, setFilteredData } = useContext(DynamicTableContext);

  useEffect(() => {
    const filteredData = data.filter((row) => {
      return Object.keys(row).some((key) => {
        if (!row[key]) return false;
        return row[key].toString().toLowerCase().includes(userInput.toLowerCase());
      });
    });
    setFilteredData(filteredData);
  }, [userInput]);

  return (
    <div className="block columns is-vcentered">
      <div className=" column is-narrow">
        <label>Filter</label>
      </div>
      <input
        className="input column is-4"
        type="text"
        value={userInput}
        onChange={(event) => {
          setUserInput(event.target.value);
        }}
      />
      {userInput && (
        <div className="column is-narrow">
          <a className="clear-link" onClick={() => setUserInput("")}>
            CLEAR
          </a>
        </div>
      )}
    </div>
  );
}

export default TextFilterComponent;
