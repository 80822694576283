import React, { createContext, useState } from "react";
import PropTypes from "prop-types";
import TableComponent from "./dynamic_table/DynamicTableComponent";
import TextFilterComponent from "./dynamic_table/TextFilterComponent";

export const DynamicTableContext = createContext();
function DynamicTable({ data, columns }) {
  const [filteredData, setFilteredData] = useState(data);
  const [sortingColumnIndex, setSortingColumnIndex] = useState(null);

  return (
    <DynamicTableContext.Provider
      value={{
        columns,
        data,
        filteredData,
        setFilteredData,
        sortingColumnIndex,
        setSortingColumnIndex
      }}
    >
      <div>
        <TextFilterComponent />
        <TableComponent />
      </div>
    </DynamicTableContext.Provider>
  );
}

DynamicTable.propTypes = {
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired
};

export default DynamicTable;
