/* eslint-disable react/prop-types */
import React from "react";

export const ClientAutoCompleteTableBody = ({ clients, error, loading }) => {
  if (loading) {
    return (
      <tr>
        <td colSpan="7" className="client-page-autocomplete-table-body-text">
          LOADING...
        </td>
      </tr>
    );
  }
  if (error) {
    return (
      <tr className="client-page-autocomplete-table-body-error">
        <td colSpan="7" className="client-page-autocomplete-table-body-text">
          {error}
        </td>
      </tr>
    );
  }
  if (clients.length === 0) {
    return (
      <tr>
        <td colSpan="7" className="client-page-autocomplete-table-body-text">
          No Clients Match Search
        </td>
      </tr>
    );
  }
  return (
    <>
      {clients.map((client) => {
        return (
          <tr key={client.id}>
            <td>
              <a href={`/clients/${client.id}`}>{client.user_code}</a>
              {client.deceased && <span className="deceased-client">(Deceased)</span>}
            </td>
            <td>{client.sis_name}</td>
            <td>{client.dob}</td>
            <td>{client.gender}</td>
            <td>{client.allergies}</td>
            <td>{client.health_conditions}</td>
            <td>{client.emergency_contact}</td>
          </tr>
        );
      })}
    </>
  );
};
