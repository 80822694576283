/* eslint-disable react/prop-types */
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useBulmaCalendar } from "../hooks/useBulmaCalendar";
import { ClientAutoCompleteTableBody } from "./client_autocomplete_table_body";

const ClientAutocomplete = () => {
  const [dob, setDOB] = useState("");
  const [SISName, setSISName] = useState("");
  const [userCode, setUserCode] = useState("");
  const [clients, setClients] = useState([]);
  const [error, setError] = useState();
  const [updateResultsTimeout, setUpdateResultsTimeout] = useState();
  const [renderTable, setRenderTable] = useState(false);
  const [loading, setLoading] = useState(false);

  const updateDOB = (newValue) => {
    if (!newValue) {
      setDOB("");
      return;
    }
    const newDateValue = new Date(newValue.data.datePicker._date.start);
    const year = newDateValue.getFullYear();
    const month = newDateValue.getMonth() + 1;
    const day = newDateValue.getDate();
    // Add leading zeros if month or day is less than 10
    const formattedMonth = month < 10 ? "0" + month : month;
    const formattedDay = day < 10 ? "0" + day : day;
    setDOB(year + "-" + formattedMonth + "-" + formattedDay);
  };

  // Make date input use bulma (UI library)
  useBulmaCalendar({
    id: "clients-page-dob-input",
    onUpdate: updateDOB,
    customOptions: {
      showFooter: true,
      showValidate: false
    }
  });

  // Controls if react or ruby on rails handles rendering clients
  useEffect(() => {
    const shouldRenderTable = SISName || dob || userCode;
    const rubyOnRailsTable = document.getElementById("clients-page-table");
    const rubyOnRailsPagination = document.getElementById("clients-page-pagination");
    if (rubyOnRailsTable) rubyOnRailsTable.style.display = shouldRenderTable ? "none" : "";
    if (rubyOnRailsPagination)
      rubyOnRailsPagination.style.display = shouldRenderTable ? "none" : "";

    setRenderTable(shouldRenderTable);

    if (!shouldRenderTable && clients.length !== 0) {
      setClients([]);
      setError(false);
      setLoading(false);
    }
  }, [SISName, dob, userCode, error, clients]);

  // Deals with input debouncing as to not rapidly send calls to search clients
  useEffect(() => {
    setError(undefined);
    if (dob.length === 0 && SISName.length === 0 && userCode.length === 0) return;
    setLoading(true);
    if (updateResultsTimeout) clearTimeout(updateResultsTimeout);
    setUpdateResultsTimeout(setTimeout(getResults, 750));

    return () => {
      clearTimeout(updateResultsTimeout);
    };
  }, [dob, SISName, userCode]);

  const getResults = async () => {
    try {
      setLoading(true);
      setClients([]);
      setError(false);
      const res = await axios.post("/clients_search_api.json", {
        sis_name: SISName,
        dob: dob,
        user_code: userCode
      });
      if (res.data && Array.isArray(res.data)) {
        setClients(res.data);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setError("failed to get clients");
    }
  };

  const updateSISName = (event) => {
    setSISName(event.target.value);
  };

  const updateUserCode = (event) => {
    setUserCode(event.target.value);
  };

  return (
    <div className="client-page-client-autocomplete-sis-name-dob-container">
      <div className="clients-page-inputs-container">
        <div className="client-page-input-container">
          <p>Date Of Birth (yyyy-mm-dd)</p>
          <input
            onChange={updateDOB}
            id="clients-page-dob-input"
            type="date"
            className="input-style-copied"
          ></input>
        </div>
        <div className="client-page-input-container">
          <p>SIS Name</p>
          <input className="input" type="text" value={SISName} onChange={updateSISName} />
        </div>
        <div className="client-page-input-container">
          <p>User Code</p>
          <input
            className="input"
            type="text"
            value={userCode}
            onChange={updateUserCode}
            data-explorator_test_id="client-autocomplete-user-code-search-input"
          />
        </div>
      </div>
      <br />
      {renderTable && (
        <table className="table is-striped">
          <thead>
            <tr>
              <th>User Code</th>
              <th>SIS Name</th>
              <th>DOB</th>
              <th>Gender</th>
              <th>Allergies</th>
              <th>Health Conditions</th>
              <th className="has-text-right">Emergency Contact</th>
            </tr>
          </thead>

          <tbody>
            <ClientAutoCompleteTableBody error={error} clients={clients} loading={loading} />
          </tbody>
        </table>
      )}
    </div>
  );
};

export default ClientAutocomplete;
